@font-face {
  font-family: 'Voyage';
  src:  url('./assets/Voyage-Regular.woff2') format('woff2'),
        url('./assets/Voyage-Regular.woff') format('woff');
}

$cream-colour: #EAE2D2;

html, body, #root {

  position: relative;
  height: 100%;

}

body {

  margin: 0;
  padding: 0;
  font-family: 'Voyage', Luminari, serif;
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $cream-colour;

}

#root {

  padding: 0.1px;

}

.Logo {

  position: fixed;
  top: -8em;
  right: 0.75em;
  width: 3em;
  height: 3em;
  opacity: 0.95;

}

.Lightbox {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $cream-colour;

  img {

    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1em;

  }

  @media screen and (max-width: 414px) {

    img {

      padding: 0.5em;

    }
  }
}

.Gallery {

  margin: 5px;

  p {

    text-align: center;
    padding-bottom: 1em;

  }
}